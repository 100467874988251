
.card-body {

  padding: 0rem 3rem;
}
.card-title{
  font-size: 15px;
	color: #21927e;
	line-height: 27px;
	font-weight: bold;
	text-transform: uppercase;
  text-align: center;
}
.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.cardtitle{
    margin-bottom: 17px;
    margin-top: 17px;
    font-weight: 900;
  }
  .card {
    text-align: center;
  padding-top: 0px;
  padding-bottom: 1px;
  box-shadow: 0px 0px 9px #ccc;
  border-radius: 10px;
  font-weight: bold;
} 
@media screen and (max-width: 480px) {
  .col-1 {
    display: none;
   } 
}
hr{
 
 width: 80%;
 display: flex;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
@media screen and (max-width: 480px) {
  .col-1 {
    flex: 0 0 auto;
  width: 100%;
   } 
}
@media screen and (max-width: 480px) {
.row {
  display: flex !important;
  flex-wrap: wrap;
}
}
@media screen and (max-width: 480px) {
*, ::after, ::before {
 box-sizing: content-box;
}
}
.hr {
  border-bottom: 1px solid #b7b7b7;
  padding-bottom: 10px;
}
.sec-two{
  margin-top: 30px;
}
.card-body {
  padding: 1rem 3rem;
}